
import { Component, Mixins, Prop, Watch } from 'vue-property-decorator';
import { VuetifyMixin, StringsMixin, BAIconsMixin, OrgResolverMixin, OrgAdminRoutingMixin, PaginatedTableMixin, AthleteApiMixin } from '@/mixins';
import { DataTableHeader } from 'vuetify';
import { PlayerOnOrgEx, PlayerOnTeam } from '@/models';
import { athleteApi } from '@/api/AthleteApi';
import { ObjectCache } from '@/helpers/object-cache';

@Component
export default class OrgAdminAthletesCard extends Mixins(VuetifyMixin, StringsMixin, BAIconsMixin, OrgResolverMixin, OrgAdminRoutingMixin, PaginatedTableMixin, AthleteApiMixin) {
    @Prop({type: Boolean}) showClubAthletes;

    localForagePersistFields: Array<string | [string, any]> = [['search', ''],['tableOptions.page', 1],['tableOptions.itemsPerPage', 10]];
    
    @Watch('showClubAthletes') async onChangeShowAthletes() {
        this.tableOptions.sortBy = ['firstName', 'lastName'];
        this.tableOptions.sortDesc = [false, false];
        if( this.showClubAthletes ) await this.loadAthletes();
    }
    @Watch('organizationId') async onChangeOrganizationId() {
        await this.loadCurrentOrganization();
        this.onChangeShowAthletes();
    }
    async mounted() {
        await this.onChangeOrganizationId();
    }

    membersEx: Array<PlayerOnOrgEx> = [];
    membersCount: number = 0;
    membersCache: ObjectCache<PlayerOnOrgEx> = new ObjectCache<PlayerOnOrgEx>();
    async loadAthletes() {
        this.isLoading = true;
        this.isLoaded = false;
        this.membersCount = this.CurrentOrganization.members.length;
        this.membersEx = await Promise.all(
            this.CurrentOrganization.members.slice(this.FirstTableItemIndex, this.LastTableItemIndex).map(async(m) => {
                const memberExCached: PlayerOnOrgEx = this.membersCache.get(m.athleteId);
                if( this.IsNotEmpty(memberExCached) ) return memberExCached;

                const memberEx = new PlayerOnOrgEx(m);
                memberEx.athlete = await athleteApi.getPublicProfile(memberEx.athleteId);
                const teamPlayer: PlayerOnTeam = memberEx.athlete?.CurrentTeam?.players.find(p => p.athleteId === memberEx.athleteId);
                memberEx.number = teamPlayer?.number;
                this.membersCache.set(memberEx);
                return memberEx;
        }));
        this.isLoaded = true;
        this.isLoading = false;
    }
    async loadTable() {
        const backupTableOptions = {...this.tableOptions};
        if( this.showClubAthletes ) await this.loadAthletes();
        this.tableOptions = {...backupTableOptions};
    }
	get TableLoading(): boolean {
		return !this.IsOrganizationLoaded || this.isLoading;
	}
	get TotalItems(): number {
		return this.membersCount;
	}
    get TableHeaders(): Array<DataTableHeader<any>> {
        let headers: Array<DataTableHeader<any>> = [ 
            { text: 'Name', value: 'firstName', sortable: false },
            { text: 'Team', value: 'team', sortable: false },
            { text: 'No.', value: 'number', sortable: false },
            // { text: 'Gender', value: 'gender', sortable: false },
            { text: 'Email', value: 'email', sortable: false },
        ];
        if( this.IsLargeScreen ) {
            headers.push({text: '', value: 'actions', sortable: false });
            headers.push({text: '', value: 'data-table-expand', sortable: false});
        }
        return headers;
    }

    async onClickAthlete(player: PlayerOnOrgEx) {
        this.gotoAthleteProfile(player.athlete);
    }
    async onClickTeam(player: PlayerOnOrgEx) {
        if( !player?.athlete?.HasTeam ) return;
        this.gotoOrgTeam(player.athlete.CurrentTeam.id);
    }
}
