
import Page from '@/views/Page.vue';
import { Component, Mixins } from 'vue-property-decorator';
import OrgCard from '@/components/orgAdmin/OrgCard.vue';
import OrgAdminLinksCard from './OrgAdminLinksCard.vue';
import { BAIconsMixin, OrgAdminRoutingMixin, OrgResolverMixin, StringsMixin, VuetifyMixin } from '@/mixins';
import FileUploadArea from '@/components/forms/FileUploadArea.vue';
import { OrganizationLandingPage, OrgField } from '@/models/organization/OrganizationModel';
import { cloneDeep } from 'lodash';

@Component({
	components: {
		Page,
		OrgCard,
		OrgAdminLinksCard,
		FileUploadArea,
	}
})
export default class OrgAdminManageStaff extends Mixins(BAIconsMixin, VuetifyMixin, StringsMixin, OrgResolverMixin, OrgAdminRoutingMixin){
	// organizationId is inherited from OrgResolverMixin
	// @Prop({ required: true }) organizationId: string;

	async created() {
		await this.loadCurrentOrganization();
	}

	isEditing: boolean = false;
	isLandingChanged: boolean = false;
	backupLanding: OrganizationLandingPage;

	get LandingPage(): OrganizationLandingPage {
		return this.CurrentOrgLandingPage;
	}
	get OrgLandingPageUrl(): string {
		return this.createPublicLink(`/club/${this.organizationId}`);
	}
	get IsLoadingLandingPage(): boolean {
		return( this.CurrentOrganizationLoading || !this.HasCurrentOrgLandingPage );
	}
	get IsSavingLandingPage(): boolean {
		return this.IsOrganizationPatching;
	}

	async onClickEdit() {
		this.backupLanding = cloneDeep(this.LandingPage);
		this.isEditing = true;
		this.onLandingChange(false);
	}
	async onLandingChange(changed: boolean = true) {
		this.isLandingChanged = changed;
	}
	async onClickCancel() {
		if( this.isLandingChanged ) {
			if( !confirm(`Are you sure you want to cancel? Your changes will not be saved.`) ) return;
		}

		this.CurrentOrganization.landingPage = cloneDeep(this.backupLanding);
		this.isEditing = false;
		this.onLandingChange(false);
	}
	async onAddField() {
		this.LandingPage.fields.push(new OrgField)
		this.onLandingChange();
	}
	async onRemoveField(index) {
		this.LandingPage.fields.splice(index, 1);
		this.onLandingChange();
	}
	async onMoveField(index, count) {
		this.onLandingChange(false);
		[this.LandingPage.fields[index], this.LandingPage.fields[index + count]] = [this.LandingPage.fields[index + count], this.LandingPage.fields[index]];
		this.onLandingChange();
	}
	async onClickSave() {
		this.SaveCurrentOrganization();
		this.isEditing = false;
		this.onLandingChange(false);
	}
	async onSubmitForm() {
		alert('This is a sample form only');
	}
}
