
import { Component, Mixins } from 'vue-property-decorator';
import Page from '@/views/Page.vue';
import OrgCard from '@/components/orgAdmin/OrgCard.vue';
import OrgAdminLinksCard from './OrgAdminLinksCard.vue';
import { VuetifyMixin, StringsMixin, BAIconsMixin, OrgResolverMixin, AthleteReportsMixin, OrgAdminRoutingMixin, ColorsMixin, ColorMixin } from '@/mixins';
import { OrganizationModel } from '@/models/organization/OrganizationModel';
import { orgAdminStore, sportStore } from '@/store';
import { AthleteReportCategoryHeading, TeamJersey } from '@/models';
import JerseyEditColor from '@/components/teams/JerseyEditColor.vue'
import { cloneDeep } from 'lodash';
import { SportModel } from '@/models/sport';

@Component({
	components: {
		Page,
		OrgCard,
		OrgAdminLinksCard,
		JerseyEditColor,
	}
})
export default class OrgAdminClubDashboard extends Mixins(VuetifyMixin, StringsMixin, BAIconsMixin, ColorsMixin, OrgResolverMixin, AthleteReportsMixin, OrgAdminRoutingMixin, ColorMixin) {
	// organizationId is inherited from OrgResolverMixin
	// @Prop({ required: true }) organizationId: string;

	async created() {
		await this.loadCurrentOrganization();
	}

	sport: SportModel;
	async OrgSportAthleteReportHeadings(): Promise<Array<AthleteReportCategoryHeading>> {
		if( this.IsEmpty(this.sport) ) this.sport = await sportStore.findSportByName(this.CurrentOrganization.sport);
		if( this.IsEmpty(this.sport) ) return [];
		return this.sport.athleteReportHeadings
	}
	async OrgSportScoutingReportLabels(): Promise<Array<string>> {
		if( this.IsEmpty(this.sport) ) this.sport = await sportStore.findSportByName(this.CurrentOrganization.sport);
		if( this.IsEmpty(this.sport) ) return [];
		return this.sport.scoutingLabels;
	}
	async OrgSportScoutingReportMoments(): Promise<Array<string>> {
		if( this.IsEmpty(this.sport) ) this.sport = await sportStore.findSportByName(this.CurrentOrganization.sport);
		if( this.IsEmpty(this.sport) ) return [];
		return this.sport.scoutingMoments;
	}
	async OrgSportScoutingReportEvaluations(): Promise<Array<string>> {
		if( this.IsEmpty(this.sport) ) this.sport = await sportStore.findSportByName(this.CurrentOrganization.sport);
		if( this.IsEmpty(this.sport) ) return [];
		return this.sport.scoutingEvaluations;
	}


	editOrganization: OrganizationModel;
	editOrganizationReady: boolean = false;
	async organizationLoadComplete() {
		this.editOrganization = new OrganizationModel().load(this.CurrentOrganization);

		// load athlete report headings from sport, if necessary
		if( this.IsEmpty(this.editOrganization.athleteReportHeadings) ) {
			if( this.IsNotEmpty(this.editOrganization.sport) ) {
				const headings = await this.OrgSportAthleteReportHeadings();
				this.editOrganization.athleteReportHeadings = cloneDeep(headings);
			}
		}
		if( this.IsEmpty(this.editOrganization.athleteReportHeadings) ) {
			this.editOrganization.athleteReportHeadings = [
				{name:'Physical', categories:[], expanded:false},
				{name:'Mental', categories:[], expanded:false},
			];
		}

		// load scouting report categories, if necessary
		// athlete labels/attributes
		if( this.IsEmpty(this.editOrganization.scoutingReportLabels) ) {
			if( this.IsNotEmpty(this.editOrganization.sport) ) {
				const labels = await this.OrgSportScoutingReportLabels();
				this.editOrganization.scoutingReportLabels = [...labels];
			}
		}
		if( this.IsEmpty(this.editOrganization.scoutingReportLabels) ) {
			this.editOrganization.scoutingReportLabels = [
				'Technical', 'Tactical', 'Physical', 'Mental'
			];
		}

		// game moments
		if( this.IsEmpty(this.editOrganization.scoutingReportMoments) ) {
			if( this.IsNotEmpty(this.editOrganization.sport) ) {
				const moments = await this.OrgSportScoutingReportMoments();
				this.editOrganization.scoutingReportMoments = [...moments];
			}
		}
		if( this.IsEmpty(this.editOrganization.scoutingReportMoments) ) {
			this.editOrganization.scoutingReportMoments = [
				'Attacking', 'Defending', 'Transition',
			];
		}

		// scouting evaluations
		if( this.IsEmpty(this.editOrganization.scoutingReportEvaluations) ) {
			if( this.IsNotEmpty(this.editOrganization.sport) ) {
				const evaluations = await this.OrgSportScoutingReportEvaluations();
				this.editOrganization.scoutingReportEvaluations = [...evaluations];
			}
		}
		if( this.IsEmpty(this.editOrganization.scoutingReportEvaluations) ) {
			this.editOrganization.scoutingReportEvaluations = [
				'Competitive', 'Resilience', 'Presence'
			];
		}

		this.editOrganizationReady = true;
	}

	athleteReportKey: number = 0;
	onRefreshView() {
		this.athleteReportKey++;
	}

	get AthleteReportHeadings(): Array<AthleteReportCategoryHeading> {
		return this.editOrganization.athleteReportHeadings;
	}
	onAddAthleteReportHeadingComplete(heading: AthleteReportCategoryHeading) {
        this.setFocus(this.$refs.athletereportHeading);
        this.editOrganization.athleteReportHeadings.push(heading);
		this.onRefreshView();
	}
	onRemoveAthleteReportHeading(index: number) {
		this.editOrganization.athleteReportHeadings.splice(index, 1);
		this.onRefreshView();
	}
    onRemoveAllAthleteReportHeadings() {
        this.editOrganization.athleteReportHeadings = [];
        this.setFocus(this.$refs.athletereportHeading);
    }
	async onDefaultAthleteReportHeadings() {
		const headings: Array<AthleteReportCategoryHeading> = await this.OrgSportAthleteReportHeadings();
		this.editOrganization.athleteReportHeadings.splice(0, this.editOrganization.athleteReportHeadings.length);
		for(const h of headings) { this.editOrganization.athleteReportHeadings.push(cloneDeep(h)) }
		this.onRefreshView();
	}
	onResetAthleteReportHeadings() {
		this.editOrganization.athleteReportHeadings.splice(0, this.editOrganization.athleteReportHeadings.length);
		for( const h of this.CurrentOrganization.athleteReportHeadings ) {
			this.editOrganization.athleteReportHeadings.push( JSON.parse(JSON.stringify(h)) );
		}
		this.onRefreshView();
	}

	newScoutingLabel: string;
	get HasScoutingLabels() {
		return this.IsNotEmpty(this.editOrganization.scoutingReportLabels);
	}
	onAddScoutingLabel() {
		this.editOrganization.scoutingReportLabels.push(this.newScoutingLabel);
		this.newScoutingLabel = '';
        this.setFocus(this.$refs.scoutingLabel);
		this.onRefreshView();
	}
	onRemoveAllScoutingLabels() {
        this.editOrganization.scoutingReportLabels = [];
        this.setFocus(this.$refs.scoutingLabel);
		this.onRefreshView();
	}
	onRemoveScoutingReportLabel(index: number) {
		this.editOrganization.scoutingReportLabels.splice(index, 1);
		this.onRefreshView();
	}
	onRemoveScoutingReportMoment(index: number) {
		this.editOrganization.scoutingReportMoments.splice(index, 1);
		this.onRefreshView();
	}
	onRemoveScoutingReportEvaluation(index: number) {
		this.editOrganization.scoutingReportEvaluations.splice(index, 1);
		this.onRefreshView();
	}
	async onDefaultScoutingReportCategories() {
		const labels: Array<string> = await this.OrgSportScoutingReportLabels();
		const moments: Array<string> = await this.OrgSportScoutingReportMoments();
		const evaluations: Array<string> = await this.OrgSportScoutingReportEvaluations();
		this.editOrganization.scoutingReportLabels = [...labels];
		this.editOrganization.scoutingReportMoments = [...moments];
		this.editOrganization.scoutingReportEvaluations = [...evaluations];
		this.onRefreshView();
	}
	onResetScoutingReportCategories() {
		this.editOrganization.scoutingReportLabels = [...this.CurrentOrganization.scoutingReportLabels];
		this.editOrganization.scoutingReportMoments = [...this.CurrentOrganization.scoutingReportMoments];
		this.editOrganization.scoutingReportEvaluations = [...this.CurrentOrganization.scoutingReportEvaluations];
		this.onRefreshView();
	}

	changesPending: boolean = false;
	editColorIndex: number;
	isEditingColor: boolean = false;
	confirmUpdatePrimary: boolean = false;
	confirmUpdateSecondary: boolean = false;
	confirmUpdateAccent: boolean = false;
	jerseyUpdateRejected: boolean = false;
	backupJerseyPrimary: TeamJersey;
	backupJerseyAlternate: TeamJersey;
	onChangeColorStart(index: number) {
		this.editColorIndex = index;
		this.backupJerseyPrimary = {...this.editOrganization.jerseyPrimary};
		this.backupJerseyAlternate = {...this.editOrganization.jerseyAlternate};
		this.isEditingColor = true;
	}
	onChangeColorComplete() {
		this.changesPending = true;
		this.moreColors = false;
		this.isEditingColor = false;

		// if jersey updates were rejected once, do not show the dialog again.
		if( this.jerseyUpdateRejected ) return;
		// update primary jersey but confirm
		switch( this.editColorIndex ) {
			case 0:
				this.editOrganization.jerseyPrimary.color = this.editOrganization.colors[0];
				const accentPrimary = this.IsCloserToBlackOrWhite(this.editOrganization.colors[0], true);
				this.editOrganization.jerseyPrimary.accent = accentPrimary;
				this.editOrganization.jerseyPrimary.number = accentPrimary;
				this.confirmUpdatePrimary = true;
				break;
			case 1:
				this.editOrganization.jerseyAlternate.color = this.editOrganization.colors[1];
				const accentAlternate = this.IsCloserToBlackOrWhite(this.editOrganization.colors[1], true);
				this.editOrganization.jerseyAlternate.accent = accentAlternate;
				this.editOrganization.jerseyAlternate.number = accentAlternate;
				this.confirmUpdateSecondary = true;
				break;
			case 2:
				this.editOrganization.jerseyPrimary.accent = this.editOrganization.colors[2];
				this.editOrganization.jerseyAlternate.accent = this.editOrganization.colors[2];
				this.editOrganization.jerseyPrimary.number = this.editOrganization.colors[2];
				this.editOrganization.jerseyAlternate.number = this.editOrganization.colors[2];
				this.confirmUpdateAccent = true;
				break;
		}
	}
	onCancelEditColor() {
		this.editOrganization.colors[this.editColorIndex] = this.CurrentOrganization.colors[this.editColorIndex];
		this.moreColors = false;
		this.isEditingColor = false;
	}
	onAcceptChangeJerseyColor() {
		this.changesPending = true;
	}
	onRejectJerseyUpdatePrimary() {
		this.editOrganization.jerseyPrimary.color = this.backupJerseyPrimary.color;
		this.editOrganization.jerseyPrimary.accent = this.backupJerseyPrimary.accent;
		this.editOrganization.jerseyPrimary.number = this.backupJerseyPrimary.number;
		this.confirmUpdatePrimary = false;
		this.jerseyUpdateRejected = true;
	}
	onRejectJerseyUpdateSecondary() {
		this.editOrganization.jerseyAlternate.color = this.backupJerseyAlternate.color;
		this.editOrganization.jerseyAlternate.accent = this.backupJerseyAlternate.accent;
		this.editOrganization.jerseyAlternate.number = this.backupJerseyAlternate.number;
		this.confirmUpdateSecondary = false;
		this.jerseyUpdateRejected = true;
	}
	onRejectJerseyUpdateAccent() {
		this.editOrganization.jerseyPrimary.accent = this.backupJerseyPrimary.accent;
		this.editOrganization.jerseyAlternate.accent = this.backupJerseyAlternate.accent;
		this.editOrganization.jerseyPrimary.number = this.backupJerseyPrimary.number;
		this.editOrganization.jerseyAlternate.number = this.backupJerseyAlternate.number;
		this.confirmUpdateAccent = false;
		this.jerseyUpdateRejected = true;
	}

	onEditLandingPage() {
		this.gotoOrgLandingPage(this.organizationId)	
	}

	savingOrganization: boolean = false;
	async onSaveOrganization() {
		this.savingOrganization = true;
		await orgAdminStore.patchOrganization(this.editOrganization);
		this.changesPending = false;
		this.savingOrganization = false;
	}

}
